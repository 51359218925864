import { resourcesIds } from 'resources'
import { getOneKey, editOneItemIdKey, updateKey } from 'keys'
import { omit } from 'ramda'
import requestHandler from 'utils/requestHandler'
import { daredropApiUrl, stackDomain } from 'constants/app'
import { DropEvent, ProductType } from 'types'
import { TokensService } from '../services/tokens.service'
import { DaredropApi } from '../utils/daredrop-api'

export function hasValidDares(dropEvent: DropEvent) {
    if (!dropEvent.dares.length) {
        return false
    }
    return dropEvent.dares.some(
        (dare) => dare.product.type !== ProductType.Placeholder
    )
}

export default {
    [resourcesIds.MANAGE_CAMPAIGN]: {
        [getOneKey]: (id: string): Record<string, any> => ({
            endpointId: 'DROP_GET_EVENT',
            payload: {
                id,
            },
        }),
        [editOneItemIdKey]: (
            rawData: Record<string, any>[]
        ): Record<string, any> => rawData,
        [updateKey]: async (params: any): Promise<Record<string, any>> => {
            const { data, previousData } = params as {
                data: DropEvent & {
                    activateInvoice: boolean
                    campaignHasNoValidDares: boolean
                    campaignHasNoInitialProduct: boolean
                    image?: string
                }
                previousData: DropEvent
            }
            const {
                approved,
                activateInvoice,
                id,
                initialProduct,
                campaignHasNoValidDares,
                campaignHasNoInitialProduct,
                image,
            } = data

            const { approved: prevApproved } = previousData

            const isApprove = prevApproved === false && approved
            const isInvoiceApprove = prevApproved === false && approved

            if (!image) {
                throw new Error('Image is required')
            }

            // is image data64? upload it
            if (image.startsWith('data:image')) {
                const ext = image.split(';')[0].split('/')[1]
                const { signedUrl, fileName } =
                    await DaredropApi.getUploadSignedUrl({
                        fileName: `${data.id}.${ext}`,
                    })
                // browser: create blob from data64
                const blob = await fetch(image).then((res) => res.blob())
                const res = await fetch(signedUrl, {
                    method: 'PUT',
                    body: blob,
                })
                if (!res.ok) {
                    throw new Error('Failed to upload image')
                }
                if (data.gameObj) {
                    data.gameObj.image = `${stackDomain}/files/${fileName}`
                }
                const newDares = data.dares.map((dare) => ({
                    ...dare,
                    games: dare.games.map((game) => ({
                        ...game,
                        image: `${stackDomain}/files/${fileName}`,
                    })),
                }))
                data.dares = newDares
            }

            if (isApprove || isInvoiceApprove) {
                if (!hasValidDares(data) && !campaignHasNoValidDares) {
                    throw new Error('Campaign has no valid dares')
                }
                if (!campaignHasNoInitialProduct && !initialProduct?.length) {
                    throw new Error('Campaign has no initial product')
                }
            }

            if (isInvoiceApprove) {
                await requestHandler({
                    url: `${daredropApiUrl}`,
                    body: {
                        endpointId: 'DROP_EDIT_EVENT',
                        payload: {
                            dropEventPayload: {
                                ...omit(['status', 'approved'], data),
                            },
                        },
                        authentication: TokensService.getAppToken(),
                    },
                    method: 'POST',
                    headers: {},
                })
            }

            if (activateInvoice) {
                await requestHandler({
                    url: `${daredropApiUrl}`,
                    body: {
                        endpointId: 'DROP_ACTIVATE_EVENT',
                        payload: {
                            id,
                            method: 'INVOICE',
                        },
                        authentication: TokensService.getAppToken(),
                    },
                    method: 'POST',
                    headers: {},
                })
            }

            if (isApprove) {
                await requestHandler({
                    url: `${daredropApiUrl}`,
                    body: {
                        endpointId: 'DROP_ADMIN_AUDIT',
                        payload: {
                            audit: 'ACTIVATE',
                            id,
                        },
                        authentication: TokensService.getAppToken(),
                    },
                    method: 'POST',
                    headers: {},
                })
            }

            return {
                endpointId: 'DROP_EDIT_EVENT',
                payload: {
                    dropEventPayload: {
                        ...omit(['status', 'approved'], data),
                    },
                },
            }
        },
    },
}
